<template>
  <div>
    <div :class="radioClass" style="word-break: break-word;word-break: break-all" v-if="!isPaper">
      <RadioGroup v-model="value">
        <Radio
          style="display: flex;margin: 10px 0 0 10px"
          v-for="(option, index) in question.question.options"
          :key="index"
          :label="option.content"
          disabled
        >
          {{ String.fromCharCode(index + 65) }}.&nbsp;
          <span v-html="option.content.replace(/<\/?p[^>]*>/gi, '').replace(/\<img src=/gi, replaceImg)" @click="changeImg($event)"></span>
        </Radio>
      </RadioGroup>
      <div v-if="question.resultStatus !== 0">
        <Alert type="success" style="margin-top: 10px">
          {{ '正确答案： ' + String.fromCharCode(correct + 65) }}
        </Alert>
        <Alert type="info" style="margin-top: 10px" v-if="question.question.analysis">
          答案解析：
          <span slot="desc" v-html="question.question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
    <div style="word-break: break-word;word-break: break-all" v-if="isPaper">
      <RadioGroup v-model="correct">
        <Radio
          style="display: flex;margin: 10px 0 0 10px"
          v-for="(option, index) in question.options"
          :key="index"
          :label="index"
          :disabled="correct !== index"
        >
          {{ String.fromCharCode(index + 65) }}.&nbsp;
          <span v-html="option.content.replace(/<\/?p[^>]*>/gi, '').replace(/\<img src=/gi, replaceImg)" @click="changeImg($event)"></span>
        </Radio>
      </RadioGroup>
      <div>
        <Alert type="info" style="margin-top: 10px" v-if="question.analysis">
          答案解析：
          <span slot="desc" v-html="question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleChoice',
  props: {
    question: {
      type: Object,
      default: function() {
        return {}
      }
    },
    answer: {
      type: String,
      default: ''
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.answer || '',
      replaceImg: '<br /><img style="width:300px;height:150px" src=',
      correct: '',
      imgList: []
    }
  },
  created() {
    let q = this.isPaper ? this.question : this.question.question
    q.options.forEach((item, index) => {
      if (item.correct === true) {
        this.correct = index
      }
    })
  },
  computed: {
    radioClass() {
      if (this.question.resultStatus === 1) {
        return 'wrong'
      } else if (this.question.resultStatus === 0) {
        return 'correct'
      } else {
        return ''
      }
    }
  },
  watch: {
    answer(val) {
      this.value = val
    }
  },
  methods: {
    changeImg(e) {
      this.$emit('changeBigImg', e)
    }
  }
}
</script>

<style scoped lang="less">
.correct ::v-deep .ivu-radio-group {
  .ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #67c23a;
  }
  .ivu-radio-wrapper.ivu-radio-group-item.ivu-radio-wrapper-checked {
    color: #67c23a;
  }
}
.wrong ::v-deep .ivu-radio-group {
  .ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #f56c6c;
  }
  .ivu-radio-wrapper.ivu-radio-group-item.ivu-radio-wrapper-checked {
    color: #f56c6c;
  }
}
</style>

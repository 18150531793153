<template>
  <div class="myAnswer flexC">
    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
      <BreadcrumbItem>考试管理</BreadcrumbItem>
      <BreadcrumbItem :to="{ path: '/exam/paperbank' }">试卷库管理</BreadcrumbItem>
      <BreadcrumbItem
        v-if="$route.query.paperBankId"
        :to="{
          path: '/exam/paperbank/' + this.paperBankId,
          query: {
            tabLabel: $route.query.tabLabel,
            id: $route.query['paperBankId']
          }
        }"
        >查看所有考试</BreadcrumbItem
      >
      <BreadcrumbItem v-else :to="{ path: '/exam/list/' }">查看所有考试</BreadcrumbItem>
      <BreadcrumbItem>试卷名称({{ $route.query.examName }})</BreadcrumbItem>
    </Breadcrumb>
    <div id="myaLoad" class="myAnswerList">
      <div style="padding-right: 50px">
        <div class="examName" style="margin-bottom: 50px">
          <span>{{ paperInfo.name }}</span>
        </div>
        <hr class="hrStyle" />
      </div>
      <div class="examTopicList">
        <ul>
          <li class="typeByTopic" v-for="(q, index) in questionList" :key="index">
            <p class="topicType">{{ q.nameType }}(共{{ q.questionVOS.length }}题, 共{{ q.totalPoints }}分)</p>
            <div v-for="(vo, topicId) in q.questionVOS" :key="topicId" style="white-space: pre-line;margin: 40px 0 20px 0">
              <p class="p1style">
                <span>{{ topicId + 1 }}、</span><span style="white-space: pre-line" v-html="removeHtml(vo.stem)" />&nbsp;&nbsp;<span class="blueFont"
                  >分值: {{ vo.points }}分</span
                >
              </p>
              <div v-if="vo.questionType === 0">
                <Radio disabled style="margin-top: 20px">正确</Radio>
                <br />
                <Radio disabled style="margin-top: 20px">错误</Radio>
              </div>
              <p v-else class="p1style" v-for="(o, opId) in vo.options" :key="'info1-' + opId" style="margin-top: 20px">
                <span style="display: flex;flex-direction: row" v-show="vo.questionType !== 3"
                  ><span>{{ String.fromCharCode(opId + 65) }}.</span><span v-html="removeHtml(o.content)"
                /></span>
              </p>
              <p v-if="vo.questionType === 0" style="margin: 20px 0;color: #3C88D3">
                <span
                  >正确答案:&nbsp;<span>{{ vo.answer }}</span></span
                >
              </p>
              <p v-if="vo.questionType === 3" style="margin: 20px 0;color: #3C88D3">
                <!--                填空-->
                <span
                  >正确答案:&nbsp;<span>
                    <span v-for="(an, index) in vo.answer" :key="'info1-' + index">{{ index + 1 }}、{{ an.answer[0] }}&nbsp;&nbsp;</span>
                  </span></span
                >
              </p>
              <p v-if="vo.questionType === 1" style="margin: 20px 0;color: #3C88D3">
                <span
                  >正确答案:&nbsp;<span>{{ String.fromCharCode(vo.answer + 65) }}</span></span
                >
              </p>
              <p v-if="vo.questionType === 2" style="margin: 20px 0;color: #3C88D3;">
                <span style="white-space: normal;"
                  >正确答案:&nbsp;<span v-for="(mVo, index) in vo.answer" :key="'mVo-' + index">
                    {{ String.fromCharCode(mVo + 65) }}{{ vo.answer !== null && index === vo.answer.length - 1 ? '' : '、' }}&nbsp;</span
                  ></span
                >
              </p>
              <ul v-if="vo.tags !== null && vo.tags.length > 0" class="tagUl p2Style">
                <li>标签:&nbsp;</li>
                <li v-for="(k, knowId) in vo.tags" :key="'k-' + knowId">
                  <span class="tagSpan">{{ k.name }}</span>
                </li>
              </ul>
              <p class="p2Style"><span class="spanStyle">解析:&nbsp;</span><span v-html="removeHtml(vo.analysis) || '暂无解析'" /></p>
            </div>
            <hr class="hrStyle" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SingleChoice from './type/SingleChoice'
import MultipleChoice from './type/MultipleChoice'
import TrueOrFalse from './type/TrueOrFalse'
import FillInBlank from './type/FillInBlank'
import ShortAnswer from './type/ShortAnswer'
import Code from './type/Code'
import exam from '@api/exam'
import examApi from '@api/exam'
import paper from '../../../api/paper'

export default {
  name: 'ResultDetail',
  components: {
    exam,
    Code,
    ShortAnswer,
    FillInBlank,
    TrueOrFalse,
    MultipleChoice,
    SingleChoice
  },
  data() {
    return {
      topicType: [],
      resultPaper: true,
      resultPaperBank: true,
      paperBankId: 0,
      paperInfo: {
        name: '',
        createTime: '2019.3.15 22:13',
        spendExamTime: '30m20s',
        localTime: [],
        expectedExamTime: 0,
        score: 95,
        questionCount: 0,
        level: 0,
        totalPoints: 0
      },
      ask: false,
      optionValue: '',
      questionList: [],
      topicCount: 0,
      IsAnswerExam: this.$route.query.IsAnswerExam,
      userId: this.$route.query.userId,
      IsLoading: null
    }
  },
  mounted() {
    this.IsLoading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.7)',
      target: document.querySelector('#myaLoad')
    })
    // this.IsLoading = true
    if (!this.$route.query.IsAnswerExam) {
      this.getExam()
    } else {
      this.getPersonExam()
    }
  },
  methods: {
    removeHtml(html) {
      let b = ''
      if (html !== '') {
        b = this.cleanWord(String(html))
      }
      return b
    },
    getTime(time) {
      //将时间戳转换成时间格式
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      date = year + '-' + month + '-' + day
      return date
    },
    getExam() {
      Number(this.$route.query.type) === 2
        ? paper.randExanQuestion(this.$route.query.id).then(res => {
            this.sortData(res.res)
          })
        : paper.getAllPaperInfoById(this.$route.query.id).then(res => {
            this.sortData(res.res)
          })
    },
    sortData(res) {
      this.paperId = res.paperId
      this.paperBankId = res.paperBankId
      this.paperInfo.name = res.name
      this.paperInfo.questionCount = res.questionAmount
      this.paperInfo.level = res.level
      this.paperInfo.totalPoints = res.totalPoints
      this.judgeExamType(res.sectionVOS)
      this.IsLoading.close()
    },
    selectChange(value) {
      this.paperInfo.localTime.map(t => {
        if (t.value === value) {
          this.paperInfo.examTime = t.label
        }
      })
      // this.getAnswerExam(value)
    },
    getPersonExam() {
      // 获取answerPaperId
      let answerPaper = []
      let paperId = paperId
      paper.getAllPaperInfoById(paperId).then(res => {
        res.res.map(a => {
          answerPaper.push({
            startTime: a.startTime,
            answerPaperId: a.answerPaperId,
            submitTime: a.submitTime
          })
          this.paperInfo.localTime.push({
            value: a.answerPaperId,
            label: this.getTime(a.submitTime),
            id: a.answerPaperId
          })
          this.optionValue = this.paperInfo.localTime[0].value
          this.paperInfo.examTime = this.paperInfo.localTime[0].label
        })
        this.IsLoading.close()
        // this.getAnswerExam(this.paperInfo.localTime[0].id)
      })
    },
    getTimeByexpect(time) {
      // 时间 天 时 分 秒
      let theTime = parseInt(time) // 需要转换的时间秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      let theTime3 = 0 // 天
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24)
            theTime2 = parseInt(theTime2 % 24)
          }
        }
      }
      let result = ''
      if (theTime > 0) {
        result = '' + parseInt(theTime) + '秒'
      }
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分' + result
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + result
      }
      if (theTime3 > 0) {
        result = '' + parseInt(theTime3) + '天' + result
      }
      return result
    },
    getAnswerByExam(exam) {
      // 整理数据，视图显示
      exam.map(e => {
        let totalByType = 0
        e.questionVOS.map(q => {
          totalByType += q.points
          if (e.name === '判断题') {
            q.options = [
              { id: 0, content: true },
              { id: 1, content: false }
            ]
          } else if (e.name === '填空题') {
            if (q.answer !== '') {
              q.answer = JSON.parse(q.answer)
            }
            q.answer = []
            q.options = q.blanks
            q.blanks.map(b => {
              q.answer.push({
                id: b.blankId,
                answer: b.blankAnswer
              })
            })
          } else if (e.name === '多选题') {
            q.answer = []
            if (q.answer !== '') {
              q.options.map((o, index) => {
                if (o.correct) {
                  q.answer.push(index)
                }
              })
            } else {
              q.answer = []
              q.options.map((o, index) => {
                if (o.correct) {
                  q.answer.push(o.content)
                  q.answer.push(index)
                }
              })
              q.color = '#EA4E18'
              q.result = false
            }
          } else if (e.name === '单选题') {
            q.answer = ''
            if (!q.options) {
              return
            }
            q.options.map((o, index) => {
              if (o.correct) {
                q.answer = o.content
                q.answer = index
              }
            })
          }
        })
        e.totalByType = totalByType
      })
      this.questionList = exam
    },
    judgeExamType(data) {
      if (data.length === 1 && data[0].name === '默认大题') {
        return this.sortOutExam(data[0])
      } else {
        this.getTypeByOrder(data)
      }
    },
    getTypeByOrder(data) {
      // 题型整理
      data.map((t, index) => {
        switch (t.order) {
          case 1: {
            t.nameType = '一、' + t.name
            break
          }
          case 2: {
            t.nameType = '二、' + t.name
            break
          }
          case 3: {
            t.nameType = '三、' + t.name
            break
          }
          case 4: {
            t.nameType = '四、' + t.name
            break
          }
          case 5: {
            t.nameType = '五、' + t.name
            break
          }
          case 6: {
            t.nameType = '六、' + t.name
            break
          }
          case 7: {
            t.nameType = '七、' + t.name
            break
          }
          case 8: {
            t.nameType = '八、' + t.name
            break
          }
          case 9: {
            t.nameType = '九、' + t.name
            break
          }
          case 10: {
            t.nameType = '十、' + t.name
            break
          }
          case 11: {
            t.nameType = '十一、' + t.name
            break
          }
          case 12: {
            t.nameType = '十二、' + t.name
            break
          }
          case 13: {
            t.nameType = '十三、' + t.name
            break
          }
          case 14: {
            t.nameType = '十四、' + t.name
            break
          }
          case 15: {
            t.nameType = '十五、' + t.name
            break
          }
          case 16: {
            t.nameType = '十六、' + t.name
            break
          }
          case 17: {
            t.nameType = '十七、' + t.name
            break
          }
          case 18: {
            t.nameType = '十八、' + t.name
            break
          }
          case 19: {
            t.nameType = '十九、' + t.name
            break
          }
          case 20: {
            t.nameType = '二十、' + t.name
            break
          }
        }
        if (index + 1 === t.order) {
          this.questionList[index] = t
        }
      })
      this.getAnswerByExam(data)
    },
    sortOutExam(exam) {
      // 默认大题试卷
      // 设置默认大题 -> 题目的答案
      exam.questionVOS.some((q, index) => {
        // 单选题
        if (q.questionType === 1) {
          q.answer = ''
          if (!q.options) {
            return true
          }
          q.options.forEach((o, index) => {
            if (o.correct) {
              q.answer = index
            }
          })
        } else if (q.questionType === 0) {
          // 判断题
          q.options = [
            { id: 0, content: true },
            { id: 1, content: false }
          ]
        } else if (q.questionType === 2) {
          // 多选题
          let answer = []
          if (!q.options) {
            return true
          }
          q.options.forEach((o, index) => {
            if (o.correct) {
              answer.push(index)
            }
          })
          q.answer = answer
        } else if (q.questionType === 3) {
          // 填空题
          let answer = []
          if (!q.blanks) {
            return true
          }
          q.blanks.forEach(b => {
            answer.push({ answer: b.blankAnswer })
          })
          q.answer = answer
        }
      })

      exam.nameType = `一、${exam.name}`
      this.questionList = []
      this.questionList.push(exam)
    }
  }
}
</script>

<style lang="less" scoped>
.flexC {
  display: flex;
  flex-direction: column;
}
ul {
  list-style: none;
}

.myAnswer {
  .hrStyle {
    margin: 30px 0;
    border: none;
    background: #afafaf;
    height: 1px;
    opacity: 0.2;
  }
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: left;
  max-height: 937px - 70px;

  .custom-breadcrumb {
    height: 20px;
  }

  .myAnswerList {
    max-width: 100%;
    padding-left: 50px;
    margin-left: 15px;
    margin-top: 30px;
    overflow-y: auto;
    overscroll-behavior: contain;
    max-height: calc(100% - 30px) !important;
    background: #fff;
    box-shadow: 0px 3px 5px rgba(175, 179, 181, 0.5);
    flex: auto;

    .examName {
      display: flex;
      align-items: center;
      margin: 20px 0 10px 0;
      height: 50px;

      span {
        font-size: 30px;
        line-height: 53px;
        font-weight: 400;
        color: #3a4e64;
      }

      .selectStyle {
        width: 150px;
        margin-left: 16px;
        min-width: 150px;
        font-size: 14px;
        color: #5e6470;

        ::v-deep .ivu-select-visible .ivu-select-selection {
          box-shadow: none;
          border: 2px solid #eaedf1;
        }
      }
    }

    .takeTime {
      height: 50px;
      display: flex;
      font-size: 14px;
      color: #333;
      line-height: 25px;
      font-weight: 400;
      margin-bottom: 50px;

      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }

      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 40px;
      }
    }
    .examTopicList {
      max-width: 100%;
      padding-right: 50px;
      ul {
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
        color: #5e6470;
        .typeByTopic {
          margin-bottom: 60px;
        }

        .topicType {
          font-size: 16px;
          line-height: 28px;
          font-weight: 600;
          color: #333;
          margin-bottom: 15px;
        }

        .p1style {
          display: flex;
          justify-content: flex-start;
          /*align-items: center;*/
          font-weight: 500;
          color: #333;
          span {
            display: flex;
            flex-direction: column;
          }
        }
        .p2Style {
          margin-top: 20px;
          display: flex;
          .spanStyle {
            white-space: nowrap;
          }
        }

        p:last-child {
          color: #666;
        }

        .blueFont {
          white-space: nowrap;
          color: #3c88d3;
        }

        .tagUl {
          color: #fff;
          font-size: 14px;
          display: flex;
          line-height: 25px;
          font-weight: 300;
          li {
            text-align: center;
            padding: 5px 10px;
            background: #5b606e;
            border-radius: 8px;
            margin-right: 5px;
          }

          li:nth-child(1) {
            padding: 5px 0;
            text-align: left;
            margin-right: 15px;
            background: none;
            border-radius: 0;
            color: #666;
          }
        }
      }
    }
  }
}
.tagSpan {
  padding: 5px;
}
span /deep/ img {
  max-width: 648px !important;
  max-height: 350px !important;
}
</style>

<template>
  <div>
    <div :class="radioClass" style="word-break: break-word;word-break: break-all" v-if="!isPaper">
      <RadioGroup v-model="value" style="margin: 5px 0 0 10px">
        <Radio label="true" disabled>正确</Radio>
        <Radio label="false" disabled>错误</Radio>
      </RadioGroup>
      <div v-if="question.resultStatus !== 0">
        <Alert type="success" style="margin-top: 10px">
          {{ question.question.answer ? '正确答案：正确' : '正确答案：错误' }}
        </Alert>
        <Alert type="info" style="margin-top: 10px" v-if="question.question.analysis">
          答案解析：
          <span slot="desc" v-html="question.question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
    <div class="paper" style="word-break: break-word;word-break: break-all" v-else>
      <RadioGroup v-model="value" style="margin: 5px 0 0 0">
        <Radio label="true" :disabled="question.answer !== 'true' ? true : false">正确</Radio>
        <Radio label="false" :disabled="question.answer !== 'false' ? true : false">错误</Radio>
      </RadioGroup>
      <div>
        <Alert type="info" style="margin-top: 10px" v-if="question.analysis">
          答案解析：
          <span slot="desc" v-html="question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrueOrFalse',
  props: {
    question: {
      type: Object,
      default: function() {
        return {}
      }
    },
    answer: {
      type: String,
      default: ''
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.answer === undefined ? '' : this.answer
    }
  },
  computed: {
    radioClass() {
      if (this.question.resultStatus === 1) {
        return 'wrong'
      } else if (this.question.resultStatus === 0) {
        return 'correct'
      } else {
        return ''
      }
    }
  },
  watch: {
    answer(val) {
      this.value = val
    }
  }
}
</script>

<style scoped lang="less">
.correct ::v-deep .ivu-radio-group {
  .ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #67c23a;
  }
  .ivu-radio-wrapper.ivu-radio-group-item.ivu-radio-wrapper-checked {
    color: #67c23a;
  }
}
.wrong ::v-deep .ivu-radio-group {
  .ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #f56c6c;
  }
  .ivu-radio-wrapper.ivu-radio-group-item.ivu-radio-wrapper-checked {
    color: #f56c6c;
  }
}
</style>

<template>
  <div>
    <div style="word-break: break-word;word-break: break-all" v-if="!isPaper">
      <div
        v-for="(blank, blankIndex) in question.question.blanks"
        :key="blankIndex"
        :class="answers[blankIndex] === blank.blankAnswer[0] ? 'correct' : 'wrong'"
      >
        <Input v-model="answers[blankIndex]" style="margin: 5px 0 0 10px;width: 98%" disabled>
          <span slot="prepend"> {{ blankIndex + 1 }}. </span>
        </Input>
      </div>
      <div v-if="question.resultStatus !== 0">
        <Alert type="success" style="margin-top: 10px">
          正确答案：
          <div slot="desc" v-for="(answer, index) in question.question.blanks" :key="index" style="color: #606266">
            {{ index + 1 }}.<span v-html="answer.blankAnswer[0]"></span>
          </div>
        </Alert>
        <Alert type="info" style="margin-top: 10px" v-if="question.question.analysis">
          答案解析：
          <span slot="desc" v-html="question.question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
    <div style="word-break: break-word;word-break: break-all" v-else>
      <div>
        <Alert type="success" style="margin-top: 10px">
          正确答案：
          <div slot="desc" v-for="(answer, index) in question.blanks" :key="index" style="color: #606266">
            {{ index + 1 }}.<span v-html="answer.blankAnswer[0]"></span>
          </div>
        </Alert>
        <Alert type="info" style="margin-top: 10px" v-if="question.analysis">
          答案解析：
          <span slot="desc" v-html="question.analysis.replace(/\<\/?p(?!re)([^>]+)?\>/g, '')" style="color: #606266"></span>
        </Alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FillInBlank',
  props: {
    question: {
      type: Object,
      default: function() {
        return {}
      }
    },
    answer: {
      type: String,
      default: ''
    },
    isPaper: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      answers: this.answer === '' ? [] : JSON.parse(this.answer)
    }
  },
  watch: {
    question() {
      this.answer === '' ? (this.answers = []) : (this.answers = JSON.parse(this.answer))
    }
  }
}
</script>

<style scoped lang="less">
.wrong ::v-deep .ivu-input-group-prepend {
  border: 1px solid #f56c6c;
  border-right: 0;
}
.wrong ::v-deep .ivu-input {
  border: 1px solid #f56c6c;
  border-left: 0;
  &:hover {
    border-color: #f56c6c;
  }
}
.correct ::v-deep .ivu-input-group-prepend {
  border: 1px solid #67c23a;
  border-right: 0;
}
.correct ::v-deep .ivu-input {
  border: 1px solid #67c23a;
  border-left: 0;
  &:hover {
    border-color: #67c23a;
  }
}
::v-deep .ivu-input {
  color: #606266;
  background-color: white;
}
</style>
